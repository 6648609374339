import { ButtonHTMLAttributes, DetailedHTMLProps, SVGAttributes } from "react";

import { ArrowIcon } from "@/components/icons/ElementIcons";
import Link from "next/link";
import clsx from "clsx";

type VariantOptions = "primary" | "secondary";

type Variant = {
  primary: string;
  secondary: string;
};

const styles: Variant = {
  primary:
    "rounded bg-sky-300 py-2 px-4 text-sm font-semibold text-slate-900 hover:bg-sky-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-300/50 active:bg-sky-500",
  secondary:
    "rounded bg-slate-800 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/50 active:text-slate-400",
};

export function Button({
  variant = "primary",
  classNaming,
  href,
  children,
}: {
  variant?: VariantOptions;
  classNaming?: string;
  href?: string;
  children: JSX.Element | string;
}) {
  classNaming = clsx(styles[variant], classNaming);

  return href ? (
    <Link href={href} className={classNaming}>
      {children}
    </Link>
  ) : (
    <button className={classNaming}>{children}</button>
  );
}

const variantStyles = {
  primary:
    "rounded bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-gradient-to-r from-secondary-500 to-primary-900 dark:text-white dark:hover:bg-gradient-to-r dark:hover:from-secondary-900 dark:hover:to-primary-900 dark:hover:primary-200",
  secondary:
    "rounded bg-zinc-100 py-1 px-3 text-zinc-900 hover:bg-zinc-200 dark:bg-zinc-800/40 dark:text-zinc-400 dark:ring-1 dark:ring-inset dark:ring-zinc-800 dark:hover:bg-zinc-800 dark:hover:text-zinc-300",
  filled:
    "rounded bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-emerald-500 dark:text-white dark:hover:bg-emerald-400",
  outline:
    "rounded py-1 px-3 text-zinc-700 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white",
  text: "text-emerald-500 hover:text-emerald-600 dark:text-emerald-400 dark:hover:text-emerald-500",
};

export const Button2 = ({
  variant = "primary",
  classNaming,
  children,
  arrow,
  href,
  action,
}: {
  variant?: "primary" | "secondary" | "filled" | "outline" | "text";
  classNaming?: string;
  children: JSX.Element | string;
  arrow?: "left" | "right";
  href?: string;
  action?: () => void;
}) => {
  const Component = href && !action ? Link : "button";

  classNaming = clsx(
    "inline-flex gap-0.5 justify-center items-center overflow-hidden text-sm font-medium transition",
    variantStyles[variant],
    classNaming
  );

  const arrowIcon = (
    <ArrowIcon
      className={clsx(
        "mt-0.5 h-5 w-5",
        variant === "text" && "relative top-px",
        arrow === "left" && "-ml-1 rotate-180",
        arrow === "right" && "-mr-1"
      )}
    />
  );

  return (
    <Component onClick={action} className={classNaming} href={href || ""}>
      {arrow === "left" && arrowIcon}
      {children}
      {arrow === "right" && arrowIcon}
    </Component>
  );
};
