import React, { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { AnimatePresence, motion } from 'framer-motion';

import { siteData } from '@/data/site';
import gradientLinkArrow from '@/public/images/lp/svgs/gradient-link-arrow.svg';

export function KomodoWalletBtnNav() {
  let router = useRouter();

  let isHomeLp = router.asPath === '/en/' || router.asPath === '/en';

  const [isScrolledPastHero, setIsScrolledPastHero] = useState(!isHomeLp);

  // Debounce function
  const debounce = <F extends (...args: any[]) => any>(func: F, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<F>) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const firstSection = document.getElementById('hero-section');
      if (firstSection && isHomeLp) {
        const sectionBottom = firstSection.offsetTop + firstSection.offsetHeight;
        setIsScrolledPastHero(window.scrollY > sectionBottom);
      } else {
        setIsScrolledPastHero(true);
      }
    };
    handleScroll();
    window.addEventListener('scroll', debounce(handleScroll, 100));
    return () => window.removeEventListener('scroll', handleScroll);
  }, [router, isHomeLp]);
  return (
    <Link
      href={!isScrolledPastHero ? siteData.downloadURL : siteData.webDEXlink}
      className='relative ml-auto desk-nav-launch_app'
    >
      <motion.button
        type='button'
        className={`flex px-3 flex-row items-center font-sans text-base font-bold  border border-none shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 py-2 rounded-[14px] relative overflow-hidden ${
          isScrolledPastHero
            ? 'hero-btn text-white justify-left'
            : 'bg-white text-black justify-center'
        } w-[160px]`}
        whileHover={{
          y: -4,
          boxShadow: '0 4px 15px rgba(84, 104, 230, 0.6)',
          transition: { duration: 0.2 },
        }}
        whileTap={{ scale: 0.95 }}
      >
        <AnimatePresence mode='wait'>
          <motion.span
            key={isScrolledPastHero ? 'download' : 'launch'}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='transform -translate-x-1/2 left-1/2'
          >
            {!isScrolledPastHero ? 'Download Wallet' : 'Launch Wallet'}
          </motion.span>
        </AnimatePresence>

        <AnimatePresence>
          {isScrolledPastHero && (
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 10 }}
              transition={{ duration: 0.2 }}
              className='absolute right-3'
            >
              <ExportedImage
                src={gradientLinkArrow}
                className='w-auto h-[19px]'
                width={16}
                height={17}
                alt='link'
              />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>
    </Link>
  );
}
