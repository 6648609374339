import Link from "next/link";
import { constants } from "constants.mjs";
import sidebarData from "@/data/docs/sidebar.json";
import { useRouter } from "next/router";

export const TocForIndex = () => {
  const router = useRouter();
  const currentPath = router.pathname + "/";
  function findLinksForCurrentPath(sidebarData: any, currentPath: any) {
    for (let key in sidebarData) {
      for (let path in sidebarData[key]) {
        const sections = sidebarData[key][path];
        for (let section of sections) {
          if (
          constants.docsPath+section.titleLink === currentPath ||
            section.links.some((link: any) => constants.docsPath+link.href === currentPath)
          ) {
            return section.links.filter((obj: any) => constants.docsPath+obj.href !== currentPath);
          }
        }
      }
    }
    return [];
  }
  const linksArray = findLinksForCurrentPath(sidebarData, currentPath);
  return (
    <div>
      {linksArray.map((item: any) => (
        <li key={item.href}>
          <Link href={constants.docsPath+item.href}>{item.title}</Link>
        </li>
      ))}
    </div>
  );
};
