import type { ReactElement, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { constants } from 'constants.mjs';

import { DocsLayout } from '@/components/shared/docs/DocsLayout';
import { KpLayout } from '@/components/shared/kp/KpLayout';
import { LpLayout } from '@/components/shared/lp/LpLayout';

import '@/styles/globals.scss';

// export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
//   getLayout?: (page: ReactElement) => ReactNode;
// };

// type AppPropsWithLayout = AppProps & {
//   Component: NextPageWithLayout;
// };

export default function App({ Component, pageProps }: AppProps) {
  let router = useRouter();

  useEffect(() => {
    const html = document.documentElement;
    const { query, asPath, pathname } = router;
    if (query && query.lang && !Array.isArray(query.lang)) {
      html.setAttribute('lang', query.lang);
    } else if (pathname !== '/404') {
      html.setAttribute('lang', asPath.split('/')[1] || 'en');
    }
  }, [router]);

  /*const getLayout = Component.getLayout || ((page) => page);

  return (
    <RecoilRoot>
      <MetaHead pageProps={pageProps} />
      {getLayout(<Component {...pageProps} />)}
    </RecoilRoot>
  );*/

  return (
    <RecoilRoot>
      {router.pathname.startsWith('/en/docs') ? (
        <DocsLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </DocsLayout>
      ) : (
        <LpLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </LpLayout>
      )}
    </RecoilRoot>
  );
}

/*   
router.pathname === '/' ||
        router.pathname === '/en' ||
        router.pathname === '/en/business' ||
        router.pathname.startsWith('/en/flyer/') ||
        router.pathname.startsWith('/en/komodo-wallet') ||
        router.pathname.startsWith('/en/ecosystem') ||
        router.pathname.startsWith('/en/community') ||
        router.pathname.startsWith('/en/contact') ||
        router.pathname.startsWith('/en/tos') ||
        router.pathname.startsWith('/en/developer') ||
        router.pathname.startsWith('/en/events/bpsaa-webinar') ||
        router.pathname.startsWith('/en/wallets') ||
        router.pathname.startsWith('/en/reports/') ||
        router.asPath.includes('/blog') ||
        router.asPath.includes('/academy') ||
        router.pathname.startsWith('/en/privacy') ||
        router.pathname.startsWith('/404') ||
        router.pathname === '_error' ? (
        <LpLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </LpLayout>
      )


         <KpLayout pageProps={pageProps}>
          <Component {...pageProps} />
        </KpLayout>

 */
