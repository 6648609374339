import { constants } from 'constants.mjs';
import platform from 'platform';

import komodoWalletDownloads from '@/data/KomodoWalletDownloads.json';

//https://github.com/bestiejs/platform.js/blob/master/doc/README.md#platformosfamily
let osFamily = platform.os.family;

function getDownloadURL(data) {
  // Mobile
  if (osFamily == 'Android') return data.android;
  if (osFamily == 'iOS') return data.ios;
  // Desktop
  if (osFamily == 'Windows') return data.windows;
  if (osFamily == 'OS X') return data.macos;
  if (osFamily == 'Linux' || 'Ubuntu' || 'Debian') return data.linux;
  return data.default;
}

let data = {
  title: constants.orgName,
  description: constants.orgDesc,
  root: 'https://komodoplatform.com',
  siteName: 'komodoplatform.com',
  generator: 'Nextjs',
  language: 'en-US',
  twitter: '@KomodoPlatform',
  komodoPlatformHome: 'https://komodoplatform.com/en/',
  socialUnfurlImage: '',
  defaultEmail: constants.helloEmail,
  gtmId: '',
  logo: '',
  tag: 'komodo',
  webDEXlink: 'https://app.komodoplatform.com/',
  swapWidgetUrl: 'https://komodo-wallet-swap-widget.web.app/', //TODO: update to kp.com version
  downloadsPage: '/en/wallet/',
  downloadURL: getDownloadURL(komodoWalletDownloads),
  apiUrl: 'https://stats-api.atomicdex.io/api/v1/',
  mobileGuidesLink: '/en/docs/komodo-wallet/mobile/',
  desktopGuidesLink: '/en/docs/komodo-wallet/desktop/',
  helloEmail: constants.helloEmail,
  social: constants.socialLinks,
  env: {},
};
if (process.env.CFP == 'yes') {
  data.root = '';
  data.env.CFP = 'yes';
}
if (process.env.NODE_ENV === 'production') {
  data.env.PRODUCTION = 'yes';
}
export const siteData = data;
