import React, { useEffect, useRef, useState } from "react";

import AnimateHeight from "react-animate-height";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import clsx from "clsx";
import { constants } from "constants.mjs";
import { useRouter } from "next/router";

export const LeftSideBarCategory = ({
  section,
  isExpanded,
  onToggle,
  activeLinkEl,
  setActiveLinkEl,
}: {
  section: any;
  isExpanded: boolean;
  onToggle: () => void;
  activeLinkEl: any;
  setActiveLinkEl: any;
}) => {
  //const [height, setHeight] = useState<number | "auto">(0);
  //const [hasMounted, setHasMounted] = useState(false); // <-- state to track if component has mounted
  let router = useRouter();
  //const path = router.pathname;

  // TODO: https://github.com/gcharang/komodo-docs-revamp-2023/issues/94 implementation here doesn't seem to work
  // const [fiveSecondsPassed, setFiveSecondsPassed] = useState(false); // State to toggle visibility of the list
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setFiveSecondsPassed(true);
  //   }, 1000); // 5 seconds

  //   return () => clearTimeout(timer);
  // }, []);
  // useEffect(() => {
  //   setHasMounted(true); // <-- set state to true on mount
  // }, []);

  // useEffect(() => {
  //   if (activeLinkEl && hasMounted && fiveSecondsPassed) {
  //     activeLinkEl.scrollIntoView({ behavior: "smooth" , block: "end", inline: "nearest"});
  //   }
  // }, [activeLinkEl, path, hasMounted,fiveSecondsPassed]);

  const categoryContents = (section: any) => {
    return section.links.length > 0 ? (
      <ul
        role="list"
        className="mt-2 ml-1 space-y-2 border-l-2 border-slate-100 dark:border-slate-800 lg:mt-4 lg:space-y-4 lg:border-slate-200"
      >
        {section.links.map((link: any) => (
          <li key={link.href} className="relative">
            <Link
              id={link.href.split("/").join("-").replace(/^-|-$/g, "")}
              ref={(el) => {
                if (
                  constants.docsPath + link.href ===
                  (router.pathname === "/" ? "/" : router.pathname + "/")
                ) {
                  setActiveLinkEl(el); // using callback ref to set active link element
                }
              }}
              href={constants.docsPath + link.href}
              className={clsx(
                "block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full",
                constants.docsPath + link.href ===
                  (router.pathname === "/" ? "/" : router.pathname + "/")
                  ? "font-semibold text-sky-500 before:bg-sky-500"
                  : "text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block dark:text-slate-400 dark:before:bg-slate-700 dark:hover:text-slate-300"
              )}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    ) : null;
  };
  return (
    <li>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={onToggle}
      >
        <h2 className="font-medium font-display text-slate-900 dark:text-white">
          {section.titleLink ? (
            <Link
              id={section.titleLink.split("/").join("-").replace(/^-|-$/g, "")}
              ref={(el) => {
                if (
                  constants.docsPath + section.titleLink ===
                  (router.pathname === "/" ? "/" : router.pathname + "/")
                ) {
                  console.log("bbbbbb");
                  setActiveLinkEl(el); // using callback ref to set active link element
                }
              }}
              href={constants.docsPath + section.titleLink}
              className={clsx(
                constants.docsPath + section.titleLink ===
                  (router.pathname === "/" ? "/" : router.pathname + "/")
                  ? "font-semibold text-sky-500 before:bg-sky-500"
                  : "text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block dark:text-slate-400 dark:before:bg-slate-700 dark:hover:text-slate-300"
              )}
            >
              {section.title}
            </Link>
          ) : (
            section.title
          )}
        </h2>
        {!section.titleLink ? (
          <ChevronRightIcon
            className={`${
              isExpanded ? "rotate-90 transform" : ""
            } h-5 w-5 text-white`}
            aria-controls={`${section.title
              .split(" ")
              .map((str: any) => str.toLowerCase())
              .join("-")}-panel`}
          />
        ) : (
          ""
        )}
      </div>
      {!section.titleLink ? (
        <AnimateHeight
          id={`${section.title
            .split(" ")
            .map((str: any) => str.toLowerCase())
            .join("-")}-panel`}
          duration={500}
          height={isExpanded ? "auto" : 0}
        >
          {categoryContents(section)}
        </AnimateHeight>
      ) : (
        categoryContents(section)
      )}
    </li>
  );
};
