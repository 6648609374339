import { atom, useRecoilState } from "recoil";

import { LangType } from "@/components/mdx/types";
import { atomKeys } from "@/store/atomKeys";

const PreferredLanguage = atom<{ preferredLanguage: LangType }>({
  key: atomKeys.preferred_language,
  default: {
    preferredLanguage: "bash",
  },
  // effects: [persistData(atomKeys.preferred_language)],
});

export const usePreferredLanguage = () => {
  const [preferredLanguage, setPreferredLanguage] =
    useRecoilState(PreferredLanguage);
  const preferred_language = preferredLanguage.preferredLanguage;
  return {
    preferred_language,
    setPreferredLanguage,
  };
};

export const MM2SettingsState = atom({
  key: atomKeys.mm2Settings,
  default: {
    Userpass: "",
    Port: "",
    Host: "",
  },
});
