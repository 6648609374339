import { Fragment, useEffect, useId, useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { constants } from 'constants.mjs';
import { nanoid } from 'nanoid';

import { LoadingIcon, SearchIcon } from '@/components/icons/ElementIcons';

import { ClusterType, SearchResult, searchHandler } from '@/lib/docs/searchHandler';

import { inter } from '@/styles/fonts';

import { debounceV2 } from '@/shared-functions/debounceV2';

function SearchDialog({
  open,
  setOpen,
  className,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
  className: string;
}) {
  let router = useRouter();

  useEffect(() => {
    if (!open) {
      return;
    }

    function onRouteChange() {
      setOpen(false);
    }

    router.events.on('routeChangeStart', onRouteChange);
    router.events.on('hashChangeStart', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', onRouteChange);
      router.events.off('hashChangeStart', onRouteChange);
    };
  }, [open, setOpen, router]);

  useEffect(() => {
    if (open) {
      return;
    }

    function onKeyDown(event: any) {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen(true);
      }
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [open, setOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog onClose={setOpen} className={clsx('fixed inset-0 z-[10000]', className)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-zinc-400/25 backdrop-blur-sm dark:bg-black/40' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto px-4 py-4 sm:py-20 sm:px-6 md:py-8 lg:px-8 lg:py-[5vh]'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto overflow-hidden rounded-lg bg-zinc-50 shadow-xl ring-1 ring-zinc-900/7.5 dark:bg-zinc-900 dark:ring-zinc-800 sm:max-w-xl'>
              <SearchInput />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function useSearchProps() {
  let buttonRef = useRef<HTMLButtonElement | null>(null);
  let [open, setOpen] = useState(false);

  return {
    buttonProps: {
      ref: buttonRef,
      onClick() {
        setOpen(true);
      },
    },
    dialogProps: {
      open,
      setOpen(open: boolean) {
        if (buttonRef.current) {
          let { width, height } = buttonRef.current.getBoundingClientRect();
          if (!open || (width !== 0 && height !== 0)) {
            setOpen(open);
          }
        }
      },
    },
  };
}

const createSearchCluster = (searchResult: SearchResult[]) => {
  // let searchCluster: Record<
  //   string,
  //   ClusterType[]
  // > = {};
  let searchCluster = new Map<string, ClusterType[]>();

  Object.values(searchResult).forEach((searchItemData) => {
    let indexSignature = searchItemData.path.split('/')[0];
    // as keyof typeof searchItemData;
    if (searchCluster.get(indexSignature)) {
      searchCluster.set(indexSignature, [
        ...(searchCluster.get(indexSignature) as ClusterType[]),
        {
          clusterName: indexSignature,
          ...searchItemData,
        },
      ]);
    } else {
      searchCluster.set(indexSignature, [
        {
          clusterName: indexSignature,
          ...searchItemData,
        },
      ]);
    }
  });
  return searchCluster;
};
const SearchResultEl = ({
  clusterName,
  // clusterLink,
  clusterResults,
}: {
  clusterName: string;
  // clusterLink: string;
  clusterResults: ClusterType[];
}) => {
  return (
    <>
      <p className='block mt-4 text-sm font-bold text-sky-500'>{clusterName.toLocaleUpperCase()}</p>
      {clusterResults.map((data) => {
        return (
          <div
            key={nanoid(12)}
            className='w-full mt-2 rounded-sm bg-slate-800 hover:ring-2 hover:ring-sky-800'
          >
            <Link
              href={`${constants.docsPath}/${data.path}`}
              className='block p-4 text-sm font-medium text-slate-500 md:text-base'
            >
              {data.docsPageTitle}
            </Link>
          </div>
        );
      })}
    </>
  );
};

const SearchInput = () => {
  const [SearchResultsReadonly, setSearchResultsReadonly] = useState<SearchResult[]>([]);
  const [imLoading, setImLoading] = useState({ id: '' });
  // modify this one for filtering and etc...
  const [searchResults, setSearchResults] = useState<Map<string, ClusterType[]>>(new Map());
  const [searchTerm, setSearchTerm] = useState<string>('');

  const searchBlogs = debounceV2(async () => {
    searchHandler({
      searchTerm,
      setSearchResultsReadonly,
    }).then(() => {
      setImLoading({ id: '' });
    });
  });
  useEffect(() => {
    setSearchResults(createSearchCluster(SearchResultsReadonly));
  }, [SearchResultsReadonly]);

  useEffect(() => {
    searchBlogs();
  }, [searchTerm]);

  return (
    <div className='p-4 bg-brand-dark h-auto max-h-[90vh] lg:max-h-[80vh] overflow-auto'>
      <div
        className={clsx(
          'group sticky top-0 left-0 lg:bg-transparent lg:bg-none lg:relative lg:top-auto lg:left-auto flex h-12',
          searchTerm && 'bg-brand-light',
        )}
      >
        <SearchIcon className='absolute top-0 w-5 h-full pointer-events-none left-3 stroke-slate-400' />
        <input
          onChange={(event) => {
            // Start loader while user is still typing
            setImLoading({ id: 'searchingDocs' });
            setSearchTerm(event.target.value);
          }}
          value={searchTerm}
          placeholder='Search docs...'
          className={clsx(
            'flex-auto appearance-none dark:bg-slate-800/75 pl-10 text-zinc-900 outline-none placeholder:text-zinc-500 focus:w-full focus:flex-none dark:text-white sm:text-sm',
          )}
        />
        <div className='absolute inset-y-0 flex items-center right-3'>
          {imLoading.id === 'searchingDocs' && (
            <LoadingIcon className='w-5 h-5 text-transparent animate-spin stroke-zinc-200 dark:stroke-transparent dark:text-sky-500' />
          )}
        </div>
      </div>
      <div className=''>
        {!!Array.from(searchResults).length
          ? Array.from(searchResults).map(([key, value]) => {
              return (
                <SearchResultEl
                  key={nanoid(12)}
                  clusterName={key}
                  // top 5 results of each
                  clusterResults={value.slice(0, 5)}
                />
              );
            })
          : searchTerm &&
            imLoading.id !== 'searchingDocs' && (
              <div className='flex items-center justify-center pt-4 text-lg text-white'>
                <h2>
                  No results found for: <span className='font-bold'>{`"${searchTerm}"`}</span>
                </h2>
              </div>
            )}
      </div>
    </div>
  );
};

export const SearchBar = () => {
  let [modifierKey, setModifierKey] = useState('Ctrl');
  let { buttonProps, dialogProps } = useSearchProps();

  useEffect(() => {
    setModifierKey(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl ');
  }, []);

  return (
    <div className={`lg:block lg:max-w-md lg:flex-auto`}>
      <button
        type='button'
        aria-label='Search button'
        className='group flex w-6 h-6 md:w-auto md:h-auto rounded-md items-center justify-between  lg:h-auto  lg:flex-none md:rounded-lg lg:py-1.5 xl:px-4 lg:pl-2.5 lg:pr-3.5 md:text-sm md:ring-1 md:ring-slate-200 md:hover:ring-slate-300 lg:dark:bg-slate-800/75 dark:md:ring-inset dark:md:ring-white/5 dark:hover:bg-slate-700/40 dark:md:hover:ring-slate-500 lg:w-60 xl:w-72'
        {...buttonProps}
      >
        <div className='flex flex-row'>
          <SearchIcon className='flex-none w-5 h-5 fill-slate-100 group-hover:fill-slate-200 dark:fill-slate-200 md:group-hover:fill-slate-100 ' />
          <span className='flex-row items-center hidden sr-only md:not-sr-only md:ml-2 md:text-slate-500 md:dark:text-slate-400 lg:flex'>
            Search docs
          </span>
        </div>
        <div className='flex-row items-center hidden ml-2 lg:flex md:text-slate-500 md:dark:text-slate-400'>
          <span className='text-black keyboard-button'>{modifierKey}</span>
          <span>+</span>
          <span className='text-black keyboard-button'>K</span>
        </div>
      </button>
      <SearchDialog className={`font-sans`} {...dialogProps} />
    </div>
  );
};
