export const constants = {
  contentRepo: 'https://github.com/KomodoPlatform/komodo-docs-mdx',
  docsPath: '/en/docs',
  domain: 'https://komodoplatform.com',
  pngLogo: '/assets/img/logo-dark.png',
  supportEmail: 'support@komodoplatform.com',
  helloEmail: 'hello@komodoplatform.com',
  socialLinks: {
    discord: 'https://komodoplatform.com/discord',
    telegram: 'https://t.me/KomodoPlatform_Official',
    linkedin: 'https://mt.linkedin.com/company/komodoplatform',
    reddit: 'https://www.reddit.com/r/komodoplatform/',
    github: 'https://www.github.com/KomodoPlatform',
    twitter: 'https://twitter.com/KomodoPlatform/',
    youtube: 'https://www.youtube.com/channel/UCtp7YrrEnZbW97sgPldyvKg',
  },
  orgName: 'Komodo Platform',
  orgDesc:
    'Komodo is an open, composable blockchain platform. With a unique multi-chain design, Komodo is focused on providing fully composable blockchain solutions for independent developers, startups, and enterprise businesses alike.',
};
