import { useEffect, useRef, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { constants } from 'constants.mjs';

import { MetaHead } from '@/components/shared/MetaHead';
import { SafeHydrate } from '@/components/shared/SafeHydrate';
import StructuredData from '@/components/shared/StructuredData';
import { ScrollToTop } from '@/components/shared/docs/ScrollToTop';
import { LpFooter } from '@/components/shared/lp/LpFooter';
import { SuperNav } from '@/components/shared/lp/SuperNav';

import { hack, inter, montserrat } from '@/styles/fonts';

import atomHero from '@/public/images/lp/index/atom-hero.png';
import { calcMetaValues } from '@/shared-functions/calcMetaValues';

const komodian_knight = {
  username: 'komodoplatform',
  commit_emails: [],
  socials: {
    twitter: 'komodoplatform',
    linkedin: '',
  },
  id: 0,
  avatar_url: '',
  image: 'komodian-knight.png',
};

const komodian_knight_data = {
  name: 'komodian_knight',
  username: komodian_knight.username,
  avatar_url: '',
  socials: komodian_knight.socials,
  image: komodian_knight.image,
};

export const LpLayout = ({ children, pageProps }: { children: JSX.Element; pageProps: any }) => {
  const router = useRouter();
  const route = router.pathname;

  const metaValues: any = calcMetaValues(pageProps, router);
  const { finTitle, finDesc, canonicalUrl, previewImgUrl } = metaValues;
  const lpStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    headline: finTitle,
    description: finDesc,
    image: previewImgUrl,
    maintainer: {
      '@type': 'Organization',
      name: constants.orgName,
      url: constants.domain,
      email: constants.supportEmail,
      logo: constants.domain + constants.pngLogo,
      sameAs: Object.values(constants.socialLinks),
      description: constants.orgDesc,
    },
  };

  return (
    <SafeHydrate>
      <div className={`w-full overflow-x-hidden text-white body overscroll-none`}>
        <MetaHead metaValues={metaValues} />
        <StructuredData data={lpStructuredData} customKey={'lp-structured-data'} />
        <SuperNav fixed={route === '/en/roadmap' ? false : true} />
        {route === '/en/komodo-wallet' ? (
          <div
            className='container relative mx-auto font-sans text-white md:grid md:gap-0 md:grid-cols-24 z-[12]'
            id='index-hero-atom-super'
          >
            <div className='hidden lg:block lg:col-span-2'></div>
            <div className='relative px-4 md:col-span-24 lg:col-span-20 xs:px-8 lg:px-0'>
              <div className='hidden lg:block lg:absolute lg:top-[5vh] xl:top-[3vh] 3xl:top-0 right-[11.7%] w-[37%] z-10'>
                <ExportedImage
                  src={atomHero}
                  alt='atom'
                  className='w-full h-auto'
                  id='index-hero-atom-desktop'
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* <div className="relative w-full">
        <div className="container flex px-4 mx-auto xs:px-8 sm:px-6 lg:px-8 xl:px-12">
          <div className="hidden lg:relative lg:block lg:flex-none"></div>
          <div
            className={`flex w-full flex-col xl:flex-row mx-auto lg:ml-auto lg:mr-0 xl:ml-auto`}
          >
            <main
              className={`overflow-x-hidden flex flex-col min-w-0 px-4 py-16 pt-5 w-full lg:pr-0 lg:pl-8 xl:px-8 2xl:px-16`}
            >
              {children}
            </main>
          </div>
        </div>
      </div> */}
        <div id='wrapper' className='h-full'>
          <div id='content' className='wrapper z-[-35] bg-lp-blackbg'>
            <div
              className='relative min-h-screen z-[10] overflow-x-hidden overflow-y-hidden'
              id='content-div'
            >
              {children}
            </div>
            <LpFooter />
            <ScrollToTop />
          </div>
        </div>
      </div>
    </SafeHydrate>
  );
};
