import { constants } from 'constants.mjs';

import previewImgTimeStamps from '@/data/docs/previewImgTimeStamps.json';

export const calcMetaValues = (pageProps, router) => {
  const { query, asPath, pathname, route } = router;
  let pageLang;
  if (query && query.lang && !Array.isArray(query.lang)) {
    pageLang = query.lang;
  } else if (pathname !== '/404') {
    pageLang = asPath.split('/')[1] || 'en';
  }
  let titlePreset = constants.orgName;
  let descriptionDefault;
  let previewImgUrl;
  if (route.startsWith('/en/docs/')) {
    titlePreset = 'Komodo Documentation';
    //TDO after publishing, use optImgPath
    //let previewImgFilePath = previewImgTimeStamps[route.replace(constants.docsPath, "")].optImgPath.slice(6);
    let previewImgFilePath = previewImgTimeStamps[route.replace(constants.docsPath, '')].slice(6);

    if (process.env.CFP == 'yes') {
      previewImgUrl = previewImgFilePath;
    } else {
      previewImgUrl = `${constants.domain}${previewImgFilePath}`;
    }
  }
  if (route.includes('/blog/')) {
    titlePreset = `Komodo Blog | ${pageLang.toUpperCase()}`;
    descriptionDefault =
      'Read Our Cryptocurrency Blog - Blockchain News, Crypto News, User Guides, Announcements, &amp; More';
  }

  const finTitle = pageProps.title
    ? pageProps.title + ' | ' + titlePreset
    : pageProps.exported_h1
      ? pageProps.exported_h1 + ' | ' + titlePreset
      : titlePreset;
  const finDesc = pageProps.description || descriptionDefault || constants.orgDesc;
  const canonicalUrl = `${constants.domain}${route}/`;

  return { finTitle, finDesc, canonicalUrl, previewImgUrl };
};
