import React, { useState } from 'react';

import { FooterItem } from './FooterItem';

export const FooterDropdown = ({ headerNo, header, footerItems, selected, setSelected }) => {
  return (
    <dl
      className='mt-6 space-y-6 mx-auto w-full xs:w-[343px] pb-3'
      style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.35)' }}
    >
      <div className='pt-6'>
        <dt
          className='text-lg'
          onClick={() => setSelected(selected !== headerNo ? headerNo : null)}
        >
          <button
            type='button'
            className='flex items-start justify-between w-full text-left text-gray-400'
            aria-controls={`faq-${headerNo}`}
            aria-expanded={selected === headerNo}
          >
            <h3 className='text-base font-bold sm:text-2xl 3xl:text-2xxl text-br-white'>
              {header}
            </h3>
            <span className='flex items-center ml-6 h-7'>
              {selected === headerNo ? (
                <svg
                  width='22'
                  height='10'
                  viewBox='0 0 16 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M15 9L8 2L1 9' stroke='white' strokeWidth='2' />
                </svg>
              ) : (
                <svg
                  width='22'
                  height='10'
                  viewBox='0 0 16 10'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M1 1L8 8L15 1' stroke='white' strokeWidth='2' />
                </svg>
              )}
            </span>
          </button>
        </dt>
        <dd
          className='pr-12 mt-2'
          id={`faq-${headerNo}`}
          style={{ display: selected === headerNo ? 'block' : 'none' }}
        >
          <div className='overflow-hidden rounded-lg'>
            <div className='relative space-y-2'>
              {footerItems.map((footerItem, index) => (
                <FooterItem key={index} {...footerItem} />
              ))}
            </div>
          </div>
        </dd>
      </div>
    </dl>
  );
};
